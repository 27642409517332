.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;

}

#mainlogo{
  margin:50px;
  position: absolute;
  z-index: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.border{
  height:100%;
  width:100%;
  position: absolute;
  z-index: 10;
  pointer-events: none;
}
.border > div{
  height:100%;
  width:100%;
  position: absolute;
}

.socials{
  color:black;
  font-size: 14px;
}
.socials a {
  margin-left: 14px;
}

.socials svg:nth-child(2){
  font-size: 13px;
}
.socials svg path{
  fill:black;
}

/* .border .frames div{
  background: url('http://www.species-in-pieces.com/img/svg/border.svg') repeat-x 50% 50%;
			width: 100%; height: 20px;
			position: absolute;
			left:0;
			top: 0;
}

.border .frames div:nth-child(2),.border .frames div:nth-child(4){
  background: url('http://www.species-in-pieces.com/img/svg/border-vert.svg') repeat-y 50% 50%;
	width: 20px;
  height: 100%;
}
.border .frames div:nth-child(3), .border .frames div:nth-child(4){
  transform: rotate(179.99deg);
  -o-transform: rotate(179.99deg); 
  -moz-transform: rotate(179.99deg); 
  -ms-transform: rotate(179.99deg); 
  -webkit-transform: rotate(179.99deg);
  bottom:0;
  top:auto;
}
.border .frames div:nth-child(3){
  bottom:0;
  top:auto;
}

.border .frames div:nth-child(4){
right:0;
left:auto;
}

.border .corners div::before, .border .corners div::after {
  content: "";
  background: url('http://www.species-in-pieces.com/img/svg/corner.svg') no-repeat center center;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 10px;
  top: 11px;
}
.border .corners div:nth-child(1)::after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  left: auto;
  right: 10px;
  top: 11px;
}
.border .corners div:nth-child(2)::before {
  left: auto;
  right: 10px;
  top: auto;
  bottom: 11px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.border .corners div:nth-child(2)::after {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  top: auto;
  bottom: 11px;
*/
/* .fadeTranslate-enter {
  opacity: 0;
  transform: translate(0, -3vh);
  position: fixed;
}

.fadeTranslate-enter.fadeTranslate-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 12s ease-in 12s, transform 12s ease-in-out 1s;
}

.fadeTranslate-exit {
  opacity: 1;
  position: fixed;
  transform: translate(0, 0);
}

.fadeTranslate-exit.fadeTranslate-exit-active {
  opacity: 0;
  transform: translate(0, 3vh);
  transition: opacity 12s ease-in, transform 12s ease-in-out;
} */
ul,p{
  padding:0px;
  margin:0px;
}
nav{
  position: fixed;
  top: 70px;
  right: 50vw;
  z-index: 999;
  padding-right: 36px;
} 




.animate-option {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animate-option input {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
