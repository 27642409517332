.container{
    width:100%;
    height:100vh;
    background-color: #e6e844;
    justify-content: center;
    overflow-y: hidden;

}
#filters{
    width:50vw;
    height:100vh;
}

.become{
    width: 90vw;
    position: absolute;
    background-color: white;
    height: 120px;
    bottom: 0;
    transform: translateY(55%);
    cursor: pointer;
    background-position: left;
    transition: .5s ease;
    background-repeat: no-repeat;
}

.notransform{
    transform: translateY(0%) !important;
}
.become .home-link{
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 14px;
    position: relative;
    padding-top: 9px;
}
.nanobar{
    position: fixed !important;
    top: initial;
    bottom: unset !important;
    max-height: 4px;
 
}
.nanobar .bar{
    background-color: black !important;
    max-height: 4px;
}
.become p{
    color:black;
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
}
.readmore-become{
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
}
.readmore-become p {
    display: inline;
}
.readmore-become div{
    width:50%;
}
.readmore-become div:nth-child(2){
text-align: right;
}
.readmore-become div:nth-child(1){
    text-align: left;
    }
/* #home_container{
    background-image: url('/grunge.png');
    background-position: center;
    background-size: cover;
} */
h1,h2,h3,h4,h5,h6, p{
    font-family: sofia-pro, sans-serif;
}
.split{
    width: 33.3333%;
    height: 100%;
    justify-content: center;
}
.split div{
    justify-content: center;
}
.flex{
    display: flex;
}
.right_text{
    position: absolute;
    right: -22px;
    top: 50%;
    transform: rotate(90deg) translateY(-50%);
}
.left_text{
    position: absolute;
    left: 22px;
    top: 50%;
    transform: rotate(-90deg) translateY(-50%);
}
.link-text a{
    text-decoration: none;
    color:black;
}
.link-text p{
    color:black;
}
.vcenter{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.home-container{
    height: 50%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
h1{
    color:white;
    font-size: 90px;
    margin: 0px 0px 0px 0px;
    font-family: sofia-pro, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

h4{
    color:white;
    font-size: 30px;
    font-weight: 200;
    font-family: sofia-pro, sans-serif;
    font-weight: 200;
    margin: 17px 0px 70px 0px;
}
.home-link{
    color:white;
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 14px;
    -webkit-transform-origin: left bottom 0;
    -ms-transform-origin: left bottom 0;
    transform-origin: left bottom 0;
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s,-webkit-transform 0.5s ease 0s;
}
.space-right{
  margin-right: 30px;
}
.button {
    text-align: center;
    text-transform: uppercase;
    position: relative;
    overflow:hidden;
    transition: .6s;
    padding-bottom: 7px
}
.button:after {
      position: absolute;
      transition: .6s;
      content: '';
      width: 0;
      left: 50%;
      bottom: 0;
      height: 1px;
      background: #f7f7f7;
      right: 0;
      left: auto;
    }

    .button:hover{
        cursor: pointer;
    }
    .button:hover:after{
        width: 100%;
        left: 0;
    }
#bottom{
    position: absolute;
    transform: translateY(44%);
    bottom: 0;
}
#bottom h1{
    font-size:200px;
}

#locations{
    background-color: white;
    width: 400px;
    height: 100vw;
    z-index: 1;
    position: absolute;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.bottom_locations{
    z-index: 999;
    text-align: center;
    left: 0;
    right: 0;
}
.beers{
    background-size: 1500px;
    --moz-transition: all 1400ms cubic-bezier(0.12,0.825,0.23,1);
    --ms-transition: all 1400ms cubic-bezier(0.12,0.825,0.23,1);
    --o-transition: all 1400ms cubic-bezier(0.12,0.825,0.23,1);
    transition: all 1.4s cubic-bezier(0.12, 0.825, 0.23, 1);
}

.split3{
    float: left;
    width: 26%;
}
.split3.split-middle{
    float: left;
    width: 17%;

}
.none{
    display:none;
}

.hidden{
    visibility: hidden;
}

.twister{
    transform: scale(.9);
    background-size: 200rem;
    height: 100%;
    background-position: center;
    animation: roll 697ms ease-in-out;
    max-width: 247px;
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
    background-position: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.beer-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:white;
    width: 80%;
}

h2{
    font-weight: 900;
    font-size:44px;
    margin:0;
    padding:0;
}
h6{
    margin:0;
    padding:0;
font-size: 30px;
}
h3{
    margin:0;
    padding:0;
    font-weight: 500;
    font-size: 18px;
    padding-top: 16px;
}
h5{
    margin-top:0px;
    font-size: 22px;
    font-weight: 500;
}
.beer-info p{
 font-size:15px;   
}

.split3:nth-child(1){
    justify-content: flex-start;
}
.split3:nth-child(3){
    justify-content: flex-end;
}
.nanobar .bar {
    background-color: white;
}

.nanobar{
    position:absolute !important;
    bottom:0px;
    top:initial
}

#buttonhover{
    position: absolute;
    height: 80vh;
    bottom:0;
    width:100vw;
    z-index: 99999;
}
#buy{
    width: 156px;
    padding: 9px;
    padding-bottom: 13px;
    color: white;
    font-family: 'Sofia-Pro';
    background-color: transparent;
    /* border: 3px solid white; */
    font-size: 18px;
    font-weight: 400;
    border-radius: 40px;
    margin-top: 27px;
    background-color: #f56166;
    border-color: #f56166;
    box-shadow: inset 0 0 0 3px #f56166;
   
}
#buy a{
    color:white;
    list-style: none;
    text-decoration: none;
}
.hov{
    width: 40%;
    height: 100%;
}
#centre{
    width: 20%;
    height: 100%;
    cursor: url('http://localhost:3000/view.png') , pointer;
}
.black{
    color:black !important;
}
#left_split{
    cursor: url('http://localhost:3000/arrowleft.png') , pointer !important;
    justify-content: flex-start;
    align-items: center;
    color: black !important;
}
#right_split{
    cursor: url('http://localhost:3000/arrow.png') , pointer !important;
    justify-content: flex-end;
    align-items: center;
    color: black !important;
}
#left_split div{
    transform: rotate(-90deg);
}
#right_split div{
    transform: rotate(90deg);
  
}
#left_split div p{
    margin-top: 30px;
}
.inner_split{
    justify-content: center;
    height: 23px;
}
.hideborderanimation{
    animation: hideborder 2s;
    animation-fill-mode: forwards;
}

.fadeinborder{
    animation: showborder .6s;
    animation-fill-mode: forwards;
}

.hide{
    display:none;
}

@keyframes roll {
    0% {
        background-position: 0;
        -webkit-transform: scale(.9);
        transform: scale(.9);
    }

    7.692% {
        background-position: 0;
        transform: scale(.9);
    }

    7.7% {
        background-position: -15.3846153846rem;
        transform: scale(.9);
    }

    15.385% {
        background-position: -15.3846153846rem;
        transform: scale(.9);
    }

    15.39% {
        background-position: -30.7692307692rem;
        transform: scale(.9);
    }

    23.077% {
        background-position: -30.7692307692rem;
        transform: scale(.9);
    }

    23.08% {
        background-position: -46.1538461538rem;
        transform: scale(.9);
    }

    30.769% {
        background-position: -46.1538461538rem;
        transform: scale(.9);
    }

    30.77% {
        background-position: -61.5384615384rem;
        transform: scale(.9);
    }

    38.462% {
        background-position: -61.5384615384rem;
        transform: scale(.9);
    }

    38.47% {
        background-position: -76.923076923rem;
        transform: scale(.9);
    }

    46.157% {
        transform: scale(.9);
        background-position: -76.923076923rem;
        
    }

    46.16% {
        transform: scale(.9);
        background-position: -92.3076923076rem;
    }

    53.846% {
        transform: scale(.9);
        background-position: -92.3076923076rem;
    }

    53.85% {
        transform: scale(.9);
        background-position: -107.6923076922rem;
    }

    61.538% {
        transform: scale(.9);
        background-position: -107.6923076922rem;
    }

    61.54% {
        transform: scale(.9);
        background-position: -123.0769230768rem;
    }

    69.231% {
        transform: scale(.9);
        background-position: -123.0769230768rem;
    }

    69.24% {
        transform: scale(.9);
        background-position: -138.4615384614rem;
    }

    76.923% {
        transform: scale(.9);
        background-position: -138.4615384614rem;
    }

    76.93% {
        transform: scale(.9);
        background-position: -153.846153846rem;
    }

    84.615% {
        transform: scale(.9);
        background-position: -153.846153846rem;
    }

    84.62% {
        transform: scale(.9);
        background-position: -169.2307692306rem;
    }

    92.308% {
        transform: scale(.9);
        background-position: -169.2307692306rem;
    }

    92.31% {
        background-position: -184.6153846152rem;
        transform: scale(.9);
    }

    99.999% {
        background-position: -184.6153846152rem;
        transform: scale(.9);
    }

    to {
        background-position: -200rem;
        -webkit-transform: scale(.9);
        --moz-transform:scale(.9);
        --ms-transform:scale(.9);
        --o-transform:scale(.9);
        transform:scale(.9);
        transform: scale(.9);
    }
}

@keyframes hideborder {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }
  @keyframes showborder {
    from {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }

.topofpile{
    position: absolute;
    z-index: 9;
    top:0;
}

.topcan{
    position: absolute;
    z-index: 10;
    top:0;
}
/* .belowcan{
    z-index: 8 !important;
} */
.move{
    transform: translateX(100%); 
}
.width{
    width:0px !important;
}

.opacityzero{
    opacity: 0;
    transition: .4s ease-in-out !important;
}

.opacitylil{
    opacity: 0.3;
    transition: .4s ease-in-out !important;
}
#filter-text{
    margin-bottom: 20px;
}
#footer{
    position: absolute;
    bottom: 36px;
    padding-left: 35px;
    z-index: 99;
}
#about-link{
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s,-webkit-transform 0.5s ease 0s;
    font-size: 17px;
    color:black !important;
    text-decoration: none !important;
}
#about-link a{     color:black !important;
    text-decoration: none !important;
}
.store{
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s,-webkit-transform 0.5s ease 0s;
}
#store-container{
    overflow: auto;
    -ms-overflow-style: none;
    width: 52%;
    float: left;
    max-height: 380px;
    padding-right: 10px;
}
#store-container h3 {
    font-size:18px;
    cursor: pointer;
}

.opacityin{
    opacity: 1 !important;
    transition: .4s ease-in-out !important;
}
.notorious{
    opacity: 1 !important;
}

.bobble{
    display:inline-block;
    color:white;
    font-size: 200px;
    margin: 0px 0px 0px 0px;
    font-family: sofia-pro, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.bobble:nth-child(1) {
    animation: bobble-one 5s ease-in-out;
}


.bobble:nth-child(2) {
    animation: bobble-two 5s ease-in-out;
}

.bobble:nth-child(3) {
    animation: bobble-three 5s ease-in-out;
}
.bobble:nth-child(4) {
    animation: bobble-four 5s ease-in-out;
}
.bobble:nth-child(5) {
    animation: bobble-four 6s ease-in-out;
}


/* 
@-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
    }
    25% {
      -webkit-transform: scale(1.04);
    }
    75% {
      -webkit-transform: scale(1);
    }   
    100% {
        -webkit-transform: scale(1.04);
      }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.04);
    }
    100% {
      transform: scale(1);
    }
  } */
  .gm-style .gm-style-iw-c{
      border-radius: 0px !important;
      box-shadow: 7px 7px 0px -1px rgba(0, 0, 0, 0.77);
  }
@keyframes bobble-one {
    0% {
        opacity: 0;
        -webkit-transform: translateY(4%);
        --moz-transform:translateY(4%);--ms-transform:translateY(4%);--o-transform:translateY(4%);transform: translateY(4%)
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(-2%);
        --moz-transform:translateY(-2%);--ms-transform:translateY(-2%);--o-transform:translateY(-2%);transform: translateY(-2%)
    }

    28% {
        opacity: 1;
        -webkit-transform: translateY(.6%);
        --moz-transform:translateY(.6%);--ms-transform:translateY(.6%);--o-transform:translateY(.6%);transform: translateY(.6%)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateY(-.6%);
        --moz-transform:translateY(-0.6%);--ms-transform:translateY(-0.6%);--o-transform:translateY(-0.6%);transform: translateY(-.6%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        --moz-transform:translateY(0);--ms-transform:translateY(0);--o-transform:translateY(0);transform: translateY(0)
    }
}

@keyframes bobble-two {
    0% {
        opacity: 0;
        -webkit-transform: translateY(4%);
        --moz-transform:translateY(4%);--ms-transform:translateY(4%);--o-transform:translateY(4%);transform: translateY(4%)
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(-2.4%);
        --moz-transform:translateY(-2.4%);--ms-transform:translateY(-2.4%);--o-transform:translateY(-2.4%);transform: translateY(-2.4%)
    }

    30% {
        opacity: 1;
        -webkit-transform: translateY(.6%);
        --moz-transform:translateY(.6%);--ms-transform:translateY(.6%);--o-transform:translateY(.6%);transform: translateY(.6%)
    }

    45% {
        opacity: 1;
        -webkit-transform: translateY(-.4%);
        --moz-transform:translateY(-0.4%);--ms-transform:translateY(-0.4%);--o-transform:translateY(-0.4%);transform: translateY(-.4%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        --moz-transform:translateY(0);--ms-transform:translateY(0);--o-transform:translateY(0);transform: translateY(0)
    }
}

@keyframes bobble-three {
    0% {
        opacity: 0;
        -webkit-transform: translateY(4%);
        --moz-transform:translateY(4%);--ms-transform:translateY(4%);--o-transform:translateY(4%);transform: translateY(4%)
    }

    15% {
        opacity: 1;
        -webkit-transform: translateY(-2%);
        --moz-transform:translateY(-2%);--ms-transform:translateY(-2%);--o-transform:translateY(-2%);transform: translateY(-2%)
    }

    30% {
        opacity: 1;
        -webkit-transform: translateY(.6%);
        --moz-transform:translateY(.6%);--ms-transform:translateY(.6%);--o-transform:translateY(.6%);transform: translateY(.6%)
    }

    40% {
        opacity: 1;
        -webkit-transform: translateY(-.3%);
        --moz-transform:translateY(-0.3%);--ms-transform:translateY(-0.3%);--o-transform:translateY(-0.3%);transform: translateY(-.3%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        --moz-transform:translateY(0);--ms-transform:translateY(0);--o-transform:translateY(0);transform: translateY(0)
    }
}

@keyframes bobble-four {
    0% {
        opacity: 0;
        -webkit-transform: translateY(4%);
        --moz-transform:translateY(4%);--ms-transform:translateY(4%);--o-transform:translateY(4%);transform: translateY(4%)
    }

    8% {
        opacity: 1;
        -webkit-transform: translateY(-2.4%);
        --moz-transform:translateY(-2.4%);--ms-transform:translateY(-2.4%);--o-transform:translateY(-2.4%);transform: translateY(-2.4%)
    }

    25% {
        opacity: 1;
        -webkit-transform: translateY(.4%);
        --moz-transform:translateY(.4%);--ms-transform:translateY(.4%);--o-transform:translateY(.4%);transform: translateY(.4%)
    }

    35% {
        opacity: 1;
        -webkit-transform: translateY(-.3%);
        --moz-transform:translateY(-0.3%);--ms-transform:translateY(-0.3%);--o-transform:translateY(-0.3%);transform: translateY(-.3%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        --moz-transform:translateY(0);--ms-transform:translateY(0);--o-transform:translateY(0);transform: translateY(0)
    }
}


#inner-container{
    margin-left: 0%;
    width: 45%;
    float: left;
    transition: ease-out 500ms;
    opacity: 0;
}

.locate_h1{
    color: black;
    text-align: left;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 300;
}
#filters{
    display:flex;
    align-items: center;
}
.pointer{
    cursor: pointer;
}
h3 {
    margin: 0;
    padding: 0;
    font-weight: 900;
    font-size: 23px;
    padding-top: 16px;
}
.region{
    font-size: 9px;
}

.region-container:hover , .home-link:hover, #about-link:hover{
    -webkit-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    transform: skewX(-8deg);

}


.store:hover{
    opacity: 0.7;
}
.region-container{
        -webkit-transform-origin: left bottom 0;
        -ms-transform-origin: left bottom 0;
        transform-origin: left bottom 0;
        transition: -webkit-transform 0.5s ease 0s;
        transition: transform 0.5s ease 0s;
        transition: transform 0.5s ease 0s,-webkit-transform 0.5s ease 0s;

  
}

#home_container {
    z-index: 1000;
    position:relative;
    transition: all 5s linear;
}
#table{
    width: 300%;
    transition: all 700ms cubic-bezier(0.77, 0.09, 0.325, 0.625) 0s;
    float: left;
    position: relative;
}
.slide{
    width: 33.333333%;
    display: table;
    table-layout: fixed;
    float: left;
}

.move_right{
    transform: translate3d(-100vw, 0px, 0px);
}

.move_left{
    transform: translate3d(100vw, 0px, 0px);
}

#shrink{
    height: 100vh;
    width: 100vw;
    background-color: #e7e7e7;
    position:absolute;
    transition: 700ms ease;
    right: 100vw;
    
}
.animate_shrink{
    width: 50vw !important;
    transition: 1000ms ease !important;
}
#filter-containers{
    height: 61vh;
    margin: 0 auto;
    width: 72%;
    margin-top: 4%;
}
#map-element{
    height:100vh;
    width:50vw;
}
#map-container-element{
    height:100vh;
    width:50vw;
}

@media screen and (max-width: 900px) {
    .animate_shrink {
        width: 100vw !important;
        transition: 500ms ease !important;
}

    
#filters{
    width: 100vw;
    height: auto;
}
#filter-containers {
    height: 600px;
    margin: 0 auto;
    width: 77%;
    margin-top: 162px;
}
#inner-container {
    margin-left: 0%;
    width: 45%;
    float: left;
    transition: ease-out 500ms;
    opacity: 0;
}
#store-container {
    overflow: auto;
    -ms-overflow-style: none;
    width: 50%;
    float: left;
    max-height: 380px;
    margin-left: 5%;
}
#map-element {
    height: 64vh;
    width: 81vw;
    margin: 0 auto;
}

#slide-container{
    display: block !important;
}
body{
    overflow-y: scroll !important;
}
.hideYX{
    overflow: hidden !important;
}

nav{
    position: absolute !important;
    top: 40px;
    right: 0 !important;
}

.fix-container{
    overflow-x: hidden;
}
.nanobar {
    top: 0 !important;
}
#map-container-elelment{
margin-bottom: 100px;
}
  }

  @media screen and (max-width: 600px) {
    h2 {

        font-size: 32px;
        padding-bottom: 20px;
      }
      #locate_h1{
          font-size: 11px;
      }
      h3{
        font-size: 19px;
      }
      #store-container h3 {
        font-size: 15px;
      }
      #filter-containers {
        height: 550px;
      }
    }
