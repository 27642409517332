
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:white;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  text-align: center;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: red;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;

}
#bigpoppa{
  background-color: #131414;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  overflow: hidden !important;
}

.border{
  height:100%;
  width:100%;
  position: absolute;
}
.border > div{
  height:100%;
  width:100%;
  position: absolute;
}
.border .frames div{
  background: url('http://www.species-in-pieces.com/img/svg/border.svg') repeat-x 50% 50%;
			width: 100%; height: 20px;
			position: absolute;
			left:0;
			top: 0;
}

.border .frames div:nth-child(2),.border .frames div:nth-child(4){
  background: url('http://www.species-in-pieces.com/img/svg/border-vert.svg') repeat-y 50% 50%;
	width: 20px;
  height: 100%;
}
.border .frames div:nth-child(3), .border .frames div:nth-child(4){
  transform: rotate(179.99deg);
  -o-transform: rotate(179.99deg); 
  -moz-transform: rotate(179.99deg); 
  -ms-transform: rotate(179.99deg); 
  -webkit-transform: rotate(179.99deg);
  bottom:0;
  top:auto;
}
.border .frames div:nth-child(3){
  bottom:0;
  top:auto;
}

.border .frames div:nth-child(4){
right:0;
left:auto;
}

.border .corners div::before, .border .corners div::after {
  content: "";
  background: url('http://www.species-in-pieces.com/img/svg/corner.svg') no-repeat center center;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 10px;
  top: 11px;
}
.border .corners div:nth-child(1)::after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  left: auto;
  right: 10px;
  top: 11px;
}
.border .corners div:nth-child(2)::before {
  left: auto;
  right: 10px;
  top: auto;
  bottom: 11px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.border .corners div:nth-child(2)::after {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  top: auto;
  bottom: 11px;
}

ul,p{
  padding:0px;
  margin:0px;
}
nav{
  position: absolute;
  top: 40px;
  right: 50px;
  z-index: 999;
}
li{
  list-style-type: none;
}
.right >ul > li > a{
   color: white;
   font-family: sofia-pro, sans-serif;
   font-weight: 700;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-decoration: none;
   font-size: 18px;
 }
 .logo{
  position: fixed;
  top: 40px;
  left: 40px;
  z-index: 999;
}

#mouse {position:absolute; background: white; opacity:1; width:10px; height:10px; border-radius:100px; top:50%; left:50%; animation: pulse .5s cubic-bezier(.51,0,0.4,.5) alternate infinite; transform: translate(-50%,-50%) scale(1);  }

@keyframes pulse {
  from { width:10;height:10px; }
  to { width:20px; height:20px; }
}

#mouse.active { animation:bounce .5s ease; }
@keyframes bounce {
 
 100%{ transform: translate(-50%,-50%) scale(2); opacity:0; }
  0%{ transform: translate(-50%,-50%) scale(1); opacity:1; }
} 

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  z-index: 5;
}
.revealer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.revealer {
  background: black;
  z-index: 15;
}

.slideIn-1 {
  animation: slideIn-1 1.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@keyframes slideIn-1 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  30% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(-200%, 0, 0);
  }
}

.fadeinout{
  animation: fadeinout 1.7s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.fadein{
  animation: fadein 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.casper{
  opacity:0;
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
